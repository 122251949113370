import React from 'react';
import { connect } from "react-redux";
import '../../../styles/elements/screens/finance.scss';
import TranslationService from "../../../app/services/TranslationService";


class EventDialogClass extends React.Component {
    renderEventDate = (event) => {
        let eventDate = new Date(event.time * 1000);
        let month= eventDate.getMonth() + 1;

        return (
            <tr>
                <td><strong>{TranslationService.trans(this.props.language, 'calendars.event.date')}</strong></td>
                <td>
                    {TranslationService.trans(this.props.language, '_date_format', {'%d': eventDate.getDate(), '%m': (month < 10 ? '0' + month : month), '%Y': eventDate.getFullYear()})}
                </td>
            </tr>
        );
    }

    renderEventTime = (event) => {
        let eventStartTime = new Date(event.time * 1000);
        let eventStartHours = eventStartTime.getHours() < 10 ? '0' + eventStartTime.getHours() : eventStartTime.getHours();
        let eventStartMinutes = eventStartTime.getMinutes() < 10 ? '0' + eventStartTime.getMinutes() : eventStartTime.getMinutes();

        let eventStopTime = new Date((event.time + event.length * 60) * 1000);
        let eventStopHours = eventStopTime.getHours() < 10 ? '0' + eventStopTime.getHours() : eventStopTime.getHours();
        let eventStopMinutes = eventStopTime.getMinutes() < 10 ? '0' + eventStopTime.getMinutes() : eventStopTime.getMinutes();

        return (
            <tr>
                <td><strong>{TranslationService.trans(this.props.language, 'calendars.event.time')}</strong></td>
                <td>
                    {eventStartHours}:{eventStartMinutes} - {eventStopHours}:{eventStopMinutes}
                </td>
            </tr>
        );
    }

    /**
     * @returns {JSX.Element}
     */
    render() {
        return (
            <dialog className={"imd-dialog imd-bg-transparent-black" + (this.props.event !== null ? ' opened' : '')}>
                <div className="imd-dialog-body imd-bg-20-default imd-text-accent">
                    <header className="imd-text-contrast">
                        {this.props.event !== null ? this.props.event.description : ''}
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.props.close()} />
                    </header>

                    <div className="imd-dialog-content">
                        <table className="imd-table imd-table-bordered imd-border-color-accent">
                            <tbody>
                                {
                                    this.props.event !== null ?
                                        this.renderEventDate(this.props.event) : ''
                                }

                                {
                                    this.props.event !== null && this.props.event.length < 1440 ?
                                        this.renderEventTime(this.props.event) : ''
                                }

                                <tr>
                                    <td style={{width: '30%'}}><strong>{TranslationService.trans(this.props.language, 'calendars.event.place')}</strong></td>
                                    <td>{this.props.event !== null ? this.props.event.place : ''}</td>
                                </tr>

                                <tr>
                                    <td><strong>{TranslationService.trans(this.props.language, 'calendars.event.participants')}</strong></td>
                                    <td>{this.props.event !== null ? this.props.event.participants : ''}</td>
                                </tr>

                                <tr>
                                    <td><strong>{TranslationService.trans(this.props.language, 'calendars.event.details')}</strong></td>
                                    <td>{this.props.event !== null ? this.props.event.details : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => this.props.close()}>
                            {TranslationService.trans(this.props.language, 'common.close')}
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: TranslationService.getLanguage(state.user)
    }
};

export const DetailsDialog = connect(mapStateToProps, {})(EventDialogClass);
