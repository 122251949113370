import React from 'react';
import { Confirmation } from "../../Confirmation";
import { config } from "../../../app/config";
import TranslationService from "../../../app/services/TranslationService";


export class RecipeEdit extends React.Component {
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        let recipe = this.props.recipe;
        recipe.removeAddress = null;

        this.state = recipe;
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.subscreen === 'edit' && prevProps.subscreen !== 'edit') {
            this.setState(this.props.recipe);
        }
    };

    /**
     * @returns {*}
     */
    mainStyle = () => {
        if (this.props.subscreen === 'edit') {
            return {display: 'block'}
        }

        return {display: 'none'}
    };

    /**
     * @param event
     */
    handleNameChange = (event) => {
        this.setState({name: event.target.value});
    };

    /**
     * @param event
     */
    handlePublicChange = (event)  => {
        this.setState({public: event.target.value === 'true'});
    };

    /**
     * @param event
     */
    handleIngredientsChange = (event) => {
        this.setState({ingredients: event.target.value});
    };

    /**
     * @param event
     */
    handleRecipeChange = (event) => {
        this.setState({recipe: event.target.value});
    };

    /**
     * @param response
     * @param address
     */
    successRemove = (response, address) => {
        this.props.reloadData();
        this.props.openSubscreen('list');
    };

    /**
     * @param response
     * @param address
     */
    errorRemove = (error, address) => {
        this.props.openSubscreen('list');
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div style={this.mainStyle()}>
                <button className="imd-button imd-button-outline imd-border-color-main imd-text-main"
                    onClick={() => this.props.saveRecipe(this.state)}
                >
                    {TranslationService.trans(this.props.lang, 'common.save')}
                </button>

                <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent"
                    onClick={() => this.props.openSubscreen('list')}
                >
                    {TranslationService.trans(this.props.lang, 'common.cancel')}
                </button>

                <button className="imd-button imd-button-outline imd-border-color-red imd-text-red"
                    onClick={() => this.setState({removeAddress: config.apiAddress + '/app/recipe/' + this.state.id})}
                >
                    {TranslationService.trans(this.props.lang, 'common.delete')}
                </button>

                <h2 className="imd-text-20-main">{TranslationService.trans(this.props.lang, 'common.name')}</h2>
                <input className="imd-form-control imd-border-color-main imd-text-main"
                   placeholder={TranslationService.trans(this.props.lang, 'common.name')}
                   value={this.state.name}
                   onChange={this.handleNameChange}
                />

                <select className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent"
                    value={this.state.voice}
                    onChange={e => {this.handlePublicChange(e)}}
                >
                    <option value="false">{TranslationService.trans(this.props.lang, 'recipes.access.private')}</option>
                    <option value="true">{TranslationService.trans(this.props.lang, 'recipes.access.public')}</option>
                </select>

                <h2 className="imd-text-20-main">{TranslationService.trans(this.props.lang, 'recipes.ingredients')}</h2>
                <textarea className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent"
                      placeholder={TranslationService.trans(this.props.lang, 'recipes.ingredients')}
                      value={this.state.ingredients}
                      style={{height: '50vh'}}
                      onChange={this.handleIngredientsChange}
                />

                <h2 className="imd-text-20-main">{TranslationService.trans(this.props.lang, 'recipes.instructions')}</h2>
                <textarea className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent"
                      placeholder={TranslationService.trans(this.props.lang, 'recipes.instructions')}
                      value={this.state.recipe}
                      style={{height: '50vh'}}
                      onChange={this.handleRecipeChange}
                />

                <Confirmation
                    address={this.state.removeAddress}
                    onSuccess={this.successRemove}
                    onError={this.errorRemove}
                />
            </div>
        );
    }
}
