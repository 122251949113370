import React from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import {config} from "../../app/config";
import {ListenButton} from './main/ListenButton';
import '../../styles/screens/main.scss';
import TranslationService from "../../app/services/TranslationService";
import { say, alert } from "../../actions";
import {WelcomeScreen} from "./main/WelcomeScreen";
import {TalkScreen} from "./main/TalkScreen";


export class MainClass extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            queryText: '',
            query: '',
            thinking: false,
            answer: '',
            instruction: null,
            data: null,
            html: null,
            talk: null,
            messages: []
        }
    }

    componentDidMount() {
        let that = this;
        axios.get(config.apiAddress + '/information')
            .then(function (response) {
                that.setState({info: response.data});
            }).catch(function(error) {
            });
    }

    getStyle() {
        return {
            display: (this.props.user !== null && this.props.user.name !== null) && (this.props.view === 'main' || this.props.view === null) ? 'block' : 'none'
        }
    }

    catchTextFromMic = (text) => {
        this.setState({queryText: '', query: text, thinking: true});
        this.sendQuery(text);
    }

    handleQueryTextChange = (event) => {
        this.setState({queryText: event.target.value});
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter' && this.state.queryText !== '') {
            event.target.blur();
            this.setState({queryText: '', query: this.state.queryText, thinking: true});
            this.sendQuery(this.state.queryText);
        }
    };

    sendQuery = (query) => {
        let that = this;
        this.setState({answer: '', html: null});

        let queryData = {query: query};
        if (this.state.talk !== null) {
            queryData.talk = this.state.talk;
        }

        let messages = this.state.messages;
        messages.push({
            query: query,
            answer: null,
            html: null,
            instruction: null,
            data: null
        });
        this.setState({messages: messages});

        axios.post(config.apiAddress + '/query?lang=' + TranslationService.getLanguage(this.props.user), queryData)
            .then(function (response) {
                if (that.state.talk === null) {
                    that.setState({talk: response.data.talk})
                }

                messages[messages.length - 1] = {
                    query: that.state.query,
                    answer: response.data.answer,
                    html: response.data.html ?? null,
                    instruction: response.data.instruction ?? null,
                    data: response.data.data ?? null
                };

                that.setState({
                    thinking: false,
                    answer: response.data.answer,
                    instruction: response.data.instruction ?? null,
                    data: response.data.data ?? null,
                    html: response.data.html ?? null,
                    messages: messages
                });

                that.props.say(response.data.answer);
            }).catch(function(error) {
                messages[messages.length - 1] = {
                    query: that.state.query,
                    answer: TranslationService.trans(that.props.language, 'main.actions.query_failed'),
                    html: null,
                    instruction: null,
                    data: null
                };

                that.setState({thinking: false, query: null, answer: TranslationService.trans(that.props.language, 'main.actions.query_failed'), messages: messages});
                that.props.say(TranslationService.trans(that.props.language, 'main.actions.query_failed'));
            });
    }

    renderInformation = () => {
        let rows = [];

        //normal
        this.state.info.forEach(function(info) {
            let className = 'imd-bg-80-blue imd-text-gray';
            if (info.type === 'success') {
                className = 'imd-bg-80-green imd-text-120-green';
            } else if (info.type === 'danger') {
                className = 'imd-bg-80-red imd-text-120-red';
            } else if (info.type === 'warning') {
                className = 'imd-bg-80-yellow imd-text-gray';
            }

            rows.push(<div className={'imd-notification ' + className}>
                <span dangerouslySetInnerHTML={{__html: info.content}} />
            </div>);
        });

        return rows;
    }

    render() {
        return (
            <div className="screen" style={this.getStyle()} id="screen-main">
                {this.renderInformation()}

                {
                    this.state.talk === null ?
                    <WelcomeScreen
                        thinking={this.state.thinking}
                        query={this.state.query}
                        answer={this.state.answer}
                        html={this.state.html}
                        instruction={this.state.instruction}
                        data={this.state.data}
                    /> :
                    <TalkScreen
                        height={'calc(100vh - ' + (180 + 60 * this.state.info.length) + 'px)'}
                        messages={this.state.messages}
                        thinking={this.state.thinking}
                    />
                }

                <div style={{position: 'fixed', bottom: 0, padding: '13px', width: 'calc(100% - 26px)'}} className="query-block">
                    <div className="imd-form-group">
                        <input
                            className="imd-text-main"
                            placeholder={TranslationService.trans(this.props.language, 'main.how_can_i_help_you')}
                            style={{background: 'none', order: 0}}
                            value={this.state.queryText}
                            onChange={this.handleQueryTextChange}
                            onKeyDown={this.handleKeyDown}
                        />
                        <ListenButton
                            startCatchTextFromMic={() => this.setState({thinking: true})}
                            stopCatchTextFromMic={() => {}}
                            catchTextFromMic={this.catchTextFromMic}
                            startListeningCallback={() => this.setState({answer: ''})}
                            alert={this.props.alert}
                            lang={this.props.language}
                        />
                        <div className="imd-decorator imd-bg-main" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user),
        view: state.view
    }
};

export const MainScreen = connect(mapStateToProps, { say, alert })(MainClass);
