import React from 'react';
import ScreenComponent from './ScreenComponent';
import { FinanceSettingsContainer } from "./finance/FinanceSettingsContainer";
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert, login, userRefresh, setView } from "../../actions";
import { config } from "../../app/config";
import axios from "axios";
import { BarChart, ResponsiveContainer, XAxis, YAxis, Bar } from "recharts";
import '../../styles/elements/screen.scss';
import TranslationService from "../../app/services/TranslationService";


export class FinanceScreen extends ScreenComponent {

    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        let date = new Date();

        this.state = {
            data: [],
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            add: {},
            settingsDialog: false
        };
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadData();
        }
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'finance';
    }

    /**
     * Load data
     */
    loadData = (month, year) => {
        if (month === undefined) {
            month = this.state.month;
        }

        if (year === undefined) {
            year = this.state.year;
        }

        this.props.preloaderShow();
        let that = this;

        that.setState({data: []});
        axios.get(config.apiAddress + '/app/finance/' + year + '/' + month)
            .then(function (response) {
                that.setState({data: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert('Nie udało się załadować danych');
                that.props.preloaderHide();
            });
    };

    /**
     * @param event
     */
    handleValueChange = (event) => {
        let data = this.state.data;

        data[event.target.dataset.key] = event.target.value.replace(',', '.');

        this.setState({data: data});
    };

    /**
     * @param event
     */
    handleAddChange = (event) => {
        let newAdd = this.state.add;

        newAdd[event.target.dataset.key] = event.target.value;

        this.setState({add: newAdd});
    };

    /**
     * @param event
     */
    handleAddEnter = (event) => {
        if (event.key === 'Enter') {
            this.addToData(event.target.dataset.key);
        }
    };

    /**
     * @param key
     */
    addToData = (key) => {
        let value = this.state.add[key];
        if (value === undefined) {
            value = '0';
        }

        value = value.replace(',', '.') * 1;
        value = Math.round((value + Number.EPSILON) * 100) / 100;

        if (isNaN(value)) {
            this.props.alert(TranslationService.trans(this.props.language, 'finance.actions.nan'));
            return;
        }

        let data = this.state.data;
        data[key] = data[key] * 1 + value;
        data[key] = Math.round((data[key] + Number.EPSILON) * 100) / 100;

        let newAdd = this.state.add;
        newAdd[key] = '';

        this.setState({data: data, add: newAdd});
        this.saveData();
    };

    /**
     * @param key
     */
    calculatePercent = (key) => {
        let sum = 0;

        let that = this;
        Object.keys(this.state.data).forEach(function(key) {
            if (key !== 'limit') {
                sum += that.state.data[key] * 1;
            }
        });

        if (sum === 0) {
            return 0;
        }

        let quotient = that.state.data[key] / sum * 100;

        return Math.round((quotient + Number.EPSILON) * 10) / 10;
    };

    /**
     * @returns {*}
     */
    renderBudgetDetails = () => {
        let sum = 0;

        let that = this;
        Object.keys(this.state.data).forEach(function(key) {
            if (key !== 'limit') {
                sum += that.state.data[key] * 1;
            }
        });

        sum = Math.round((sum + Number.EPSILON) * 100) / 100;

        return (
            <div className="imd-notification imd-bg-20-silver imd-text-gray">
                <strong>{TranslationService.trans(this.props.language, 'finance.budget')}</strong><br />
                {Math.round((sum / (that.state.data.limit * 1)) * 10000) / 100} %
            </div>
        );
    };

    /**
     * @returns {*}
     */
    renderBalance = () => {
        let sum = 0;
        let className = 'green';

        let that = this;
        Object.keys(this.state.data).forEach(function(key) {
            if (key !== 'limit') {
                sum += that.state.data[key] * 1;
            }
        });

        let diff = Math.round((that.state.data.limit * 1 - sum) * 100) / 100;
        if (diff < 0) {
            className = 'red'
        }

        return (
            <div className={'imd-notification imd-bg-80-' + className + ' imd-text-' + className}>
                <strong>{TranslationService.trans(this.props.language, 'finance.balance')}</strong><br />
                {diff} PLN
            </div>
        );
    };


    /**
     * @returns {*}
     */
    renderChart = () => {
        return (
            <div style={{height: '300px'}}>
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={this.getChartData()}>
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, 100]} />
                        <Bar dataKey="value" fill="#ff6400" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    /**
     * @returns {[]}
     */
    getChartData = () => {
        if (Object.keys(this.state.data).length === 0) {
            return [];
        }

        let rows = [];

        let that = this;
        Object.keys(this.state.data).forEach(function(key) {
            if (key !== 'limit') {
                rows.push({
                    name: that.elementName(key),
                    value: that.calculatePercent(key)
                });
            }
        });

        return rows;
    };

    /**
     * @returns {[]}
     */
    renderData = () => {
        let rows = [];

        let that = this;
        Object.keys(this.state.data).forEach(function(key) {
            if (key === 'limit') {
                return;
            }

            rows.push(<tr key={key}>
                <td>
                    <label className="imd-form-label">
                        <span className="imd-text-80-main">{that.elementName(key)}</span>
                        <input className="imd-form-control imd-border-color-main imd-text-main" data-key={key} value={that.state.data[key]} onChange={that.handleValueChange} onBlur={that.saveData} />
                    </label>
                </td>
                <td>
                    <div className="imd-form-group" style={{width: '100px'}}>
                        <input className="imd-text-main" style={{width: '70px', background: 'none'}} placeholder="0.00" value={that.state.add[key]} data-key={key} onChange={that.handleAddChange} onKeyDown={that.handleAddEnter} />
                        <button className="imd-bg-20-main imd-text-white" onClick={() => that.addToData(key)}>
                            +
                        </button>
                        <div className="imd-decorator imd-bg-main" />
                    </div>
                </td>
                <td>
                    <span className="imd-text-contrast">
                        {that.calculatePercent(key)}%
                    </span>
                </td>
            </tr>);
        });

        return rows;
    };

    /**
     * Save device
     */
    saveData = () => {
        this.props.preloaderShow();
        let that = this;

        axios.put(config.apiAddress + '/app/finance/' + that.state.year + '/' + that.state.month, that.state.data)
            .then(function (response) {
                that.props.alert(TranslationService.trans(that.props.language, 'finance.actions.saved'));
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert(TranslationService.trans(that.props.language, 'finance.actions.failed'));
                that.props.preloaderHide();
            });
    };

    /**
     * @param name
     */
    elementName = (name)  => {
        const simpleCategories = {
            "flat": TranslationService.trans(this.props.language, 'finance.default_categories.flat'),
            "bills": TranslationService.trans(this.props.language, 'finance.default_categories.bills'),
            "food": TranslationService.trans(this.props.language, 'finance.default_categories.food'),
            "party": TranslationService.trans(this.props.language, 'finance.default_categories.party'),
            "other": TranslationService.trans(this.props.language, 'finance.default_categories.other'),
        };

        if (simpleCategories[name] !== undefined) {
            return simpleCategories[name];
        }

        return name;
    };

    /**
     * @param event
     */
    changeMonth = (event) => {
        let monthId = event.target.value;
        this.setState({month: monthId});
        this.loadData(monthId);
    };

    /**
     * @param event
     */
    changeYear = (event) => {
        let year = event.target.value;
        this.setState({year: year});
        this.loadData(this.state.month, year);
    };

    /**
     * @returns {XML}
     */
    render() {
        let years = [];
        let date = new Date();
        for (let d = date.getFullYear(); d > date.getFullYear() - 10; d--) {
            years.push(d);
        }

        return (
            <div className="screen" style={this.getStyle()} id="screen-finance">
                <div className="content">
                    <div className="imd-row">
                        <div className="imd-col-xs-5 imd-col-sm-4 imd-col-md-3 imd-col-lg-2" style={{paddingTop: '2px'}}>
                            <select className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" onChange={this.changeMonth} value={this.state.month}>
                                {TranslationService.getMonthsList(this.props.language).map((month, i) => <option value={month.id} key={i}>{month.name}</option>)}
                            </select>
                        </div>

                        <div className="imd-col-xs-4 imd-col-sm-3 imd-col-md-2 imd-col-lg-1" style={{paddingTop: '2px'}}>
                            <select className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" onChange={this.changeYear} value={this.state.year}>
                                {years.map((year, i) => <option value={year} key={i}>{year}</option>)}
                            </select>
                        </div>

                        <div className="imd-col-xs-3">
                            <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={() => this.setState({settingsDialog: true})}>
                                <span className="imd-icon imd-icon-cogs" />
                            </button>
                        </div>
                    </div>

                    <div className="imd-row">
                        <div className="imd-col">
                            {this.renderBalance()}
                        </div>

                        <div className="imd-col">
                            {this.renderBudgetDetails()}
                        </div>
                    </div>

                    <label className="imd-form-label">
                        <span className="imd-text-20-main">Limit</span>
                        <input className="imd-form-control imd-border-color-main imd-text-main" data-key="limit" value={this.state.data.limit} onChange={this.handleValueChange} onBlur={this.saveData} />
                    </label>


                    {this.renderChart()}

                    <hr />

                    <table className="imd-table">
                        <tbody>
                            {this.renderData()}
                        </tbody>
                    </table>

                    <FinanceSettingsContainer
                        show={this.state.settingsDialog}
                        closeEvent={() => this.setState({settingsDialog: false})}
                        saveSettings={() => {this.setState({settingsDialog: false}); this.loadData()}}
                        elementName={this.elementName}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        user: state.user,
        view: state.view,
        language: TranslationService.getLanguage(state.user)
    }
};
const mapDispatchToProps = { preloaderShow, preloaderHide, alert, login, userRefresh, setView };

export const FinanceScreenContainer = connect(mapStateToProps, mapDispatchToProps)(FinanceScreen);
