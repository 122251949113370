import React from 'react';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../../actions';
import '../../../styles/elements/screens/finance.scss';
import TranslationService from "../../../app/services/TranslationService";
import axios from "axios";
import {config} from "../../../app/config";


class NewCalendarDialogClass extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            nameValue: '',
            nameClass: 'main',
            urlValue: '',
            urlClass: 'main',
            color: 'blue',
            remainders: false
        }
    }

    renderColors = () => {
        let colors = ['red', 'magenta', 'violet', 'blue', 'cyan', 'teal', 'green', 'lime', 'yellow', 'amber', 'orange', 'brown'];
        let rows = [];

        let that = this;
        colors.forEach(function(color, key) {
            rows.push(<div
                key={key}
                className={'calendar-color selector imd-border-color-accent imd-bg-' + color + (color === that.state.color ? ' active' : '')}
                onClick={() => that.setState({color: color})}
            />);
        });

        return rows;
    }

    save = () => {
        let errors = 0;
        if (this.state.nameValue === '') {
            this.setState({nameClass: 'red'});
            errors++;
        } else {
            this.setState({nameClass: 'main'});
        }

        if (this.state.urlValue === '') {
            this.setState({urlClass: 'red'});
            errors++;
        } else {
            this.setState({urlClass: 'main'});
        }

        if (errors) {
            return;
        }

        let that = this;
        axios.post(config.apiAddress + '/app/calendar/calendars', {name: this.state.nameValue, url: this.state.urlValue, color: this.state.color, remainders: this.state.remainders})
            .then(function (response) {
                that.setState({
                    nameValue: '',
                    urlValue: '',
                    newColor: 'blue',
                    remainders: false
                });
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'calendars.subscribed_calendars.added'));
                that.props.close();
                that.props.callbackSuccess();
            }).catch(function(error) {
                that.props.alert(TranslationService.trans(that.props.language, 'calendars.data_loading_error'));
                that.props.preloaderHide();
            });
    }

    /**
     * @returns {JSX.Element}
     */
    render() {
        return (
            <dialog className={'imd-dialog imd-bg-transparent-black' + (this.props.opened ? ' opened' : '')}>
                <div className="imd-dialog-body imd-bg-20-default imd-text-accent">
                    <header className="imd-text-contrast">
                        {TranslationService.trans(this.props.language, 'calendars.subscribed_calendars.add_new')}
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.props.close()} />
                    </header>

                    <div className="imd-dialog-content">
                        <label className="imd-form-label">
                            <span className={'imd-text-80-' + this.state.nameClass}>{TranslationService.trans(this.props.language, 'common.name')}</span>
                            <input
                                className={'imd-form-control imd-border-color-' + this.state.nameClass + ' imd-text-' + this.state.nameClass}
                                value={this.state.nameValue}
                                onChange={e => {this.setState({nameValue: e.target.value});}}
                            />
                        </label>

                        <label className="imd-form-label">
                            <span className={'imd-text-80-' + this.state.urlClass}>{TranslationService.trans(this.props.language, 'common.url')}</span>
                            <input
                                className={'imd-form-control imd-border-color-' + this.state.urlClass + ' imd-text-' + this.state.urlClass}
                                value={this.state.urlValue}
                                onChange={e => {this.setState({urlValue: e.target.value});}}
                            />
                        </label>

                        {this.renderColors()}
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => this.props.close()}>
                            {TranslationService.trans(this.props.language, 'common.close')}
                        </button>

                        <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={() => this.save()}>
                            {TranslationService.trans(this.props.language, 'common.add')}
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user)
    }
};

export const NewCalendarDialog = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(NewCalendarDialogClass);
