import React from 'react';
import TranslationService from "../../../app/services/TranslationService";


export class RecipePreview extends React.Component {
    /**
     * @returns {*}
     */
    mainStyle = () => {
        if (this.props.subscreen === 'preview') {
            return {display: 'block'}
        }

        return {display: 'none'}
    };

    /**
     * @returns {{display: string}}
     */
    getStyleForEditButtons = () => {
        if (this.props.recipe.public !== undefined) {
            return {display: 'inline-block'}
        }

        return {display: 'none'}
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div style={this.mainStyle()}>
                <button className="imd-button imd-button-outline imd-border-color-main imd-text-main"
                    onClick={() => this.props.openSubscreen('list')}>
                    {TranslationService.trans(this.props.lang, 'common.back')}
                </button>

                <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent"
                    onClick={() => this.props.openSubscreen('edit')}
                    style={this.getStyleForEditButtons()}
                >
                    {TranslationService.trans(this.props.lang, 'common.edit')}
                </button>

                <h1 className="imd-text-main">{this.props.recipe.name}</h1>

                <h2 className="imd-text-20-main">{TranslationService.trans(this.props.lang, 'recipes.ingredients')}</h2>
                <div className="imd-text-accent" style={{whiteSpace: 'pre-line'}}>
                    {this.props.recipe.ingredients}
                </div>

                <h2 className="imd-text-20-main">{TranslationService.trans(this.props.lang, 'recipes.instructions')}</h2>
                <div className="imd-text-accent" style={{whiteSpace: 'pre-line'}}>
                    {this.props.recipe.recipe}
                </div>
            </div>
        );
    }
}
