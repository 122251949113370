import React from 'react';
import axios from 'axios';
import { alert, preloaderShow, preloaderHide } from '../../../actions';
import { connect } from "react-redux";
import TranslationService from "../../../app/services/TranslationService";

export class AlarmCodeConfirmation extends React.Component {
    /**
     * Constructor
     */
    constructor(props)
    {
        super(props);

        this.state = {
            address: this.props.address,
            code: ''
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.address !== this.props.address) {
            this.setState({address: this.props.address});
        }
    }

    getClassName = () => {
        if (this.state.address !== null) {
            return 'imd-dialog imd-dialog-sm imd-bg-transparent-black opened';
        }

        return 'imd-dialog imd-dialog-sm imd-bg-transparent-black';
    };

    action = () => {
        let that = this;
        this.props.preloaderShow();

        axios.patch(this.state.address, {code: this.state.code})
            .then(function (response) {
                that.setState({address: null, code: ''});
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'nyumba.alarm.actions.send_command'));

                setTimeout(
                    function () {
                        that.props.callback();
                    },
                    1000
                );
            }).catch(function(response) {
                that.setState({address: null, code: ''});
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'nyumba.alarm.actions.send_command_failed'));

                setTimeout(
                    function () {
                        that.props.callback();
                    },
                    1000
                );
            });
    };

    cancel = () => {
        this.setState({address: null});
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <dialog className={this.getClassName()}>
                <div className="imd-dialog-body imd-bg-20-default imd-text-accent">
                    <header>
                        {TranslationService.trans(this.props.language, 'nyumba.alarm.enter_code')}
                    </header>

                    <div className="imd-dialog-content">
                        <input type="number" autocomplete="off" placeholder="XXXXXX" className="imd-form-control imd-border-color-main imd-text-main ng-pristine ng-untouched ng-valid" value={this.state.code} onChange={e => {this.setState({code: e.target.value});}} />
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => this.cancel()}>
                            {TranslationService.trans(this.props.language, 'common.cancel')}
                        </button>
                        <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={() => this.action()}>
                            {TranslationService.trans(this.props.language, 'common.ok')}
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        email: state.login.email,
        device: state.login.device,
        language: TranslationService.getLanguage(state.user),
    }
};

export const AlarmCodeConfirmationContainer = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(AlarmCodeConfirmation);
