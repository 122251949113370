import React from 'react';
import axios from 'axios';
import { Confirmation } from '../../Confirmation';
import { config } from '../../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../../actions';
import TranslationService from "../../../app/services/TranslationService";


class EventDialogClass extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        let date = new Date();

        this.state = {
            loaded: false,
            date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()),
            time: (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
            length: 60,
            participants: '',
            place: '',
            type: 'event',
            repeat: '',
            description: '',
            delete_event: null
        }
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.eventId !== null) {
            if (!this.state.loaded) {
                this.openEvent();
            }
        }
    };

    /**
     * Load events for week
     */
    openEvent = () => {
        let date = new Date();
        this.setState({
            loaded: true,
            date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()),
            time: (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
            length: 60,
            participants: '',
            place: '',
            type: 'event',
            repeat: '',
            description: '',
            delete_event: null
        });

        if (this.props.eventId === '__NEW__') {
            return;
        }

        this.props.preloaderShow();
        let that = this;

        axios.get(config.apiAddress + '/app/calendar/event/' + that.props.eventId)
            .then(function (response) {
                let date = new Date(response.data.time * 1000);

                that.setState({
                    date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()),
                    time: (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
                    length: response.data.length,
                    participants: response.data.participants ? response.data.participants : '',
                    place: response.data.place ? response.data.place : '',
                    type: response.data.type,
                    repeat: response.data.repeat ? response.data.repeat : '',
                    description: response.data.description
                });
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'common.actions.save_failed'));
            });
    };

    /**
     * Load events for week
     */
    saveEvent = () => {
        this.props.preloaderShow();
        let that = this;
        let data = {
            date: that.state.date,
            time: that.state.time,
            length: that.state.length,
            type: that.state.type,
            description: that.state.description,
            repeat: that.state.repeat,
            participants: that.state.participants,
            place: that.state.place
        }

        if (this.props.eventId !== '__NEW__') {
            axios.put(config.apiAddress + '/app/calendar/event/' + this.props.eventId, data)
                .then(function (response) {
                    //I'm not hide poreloader because it will be hidden by CalendarEventsContainer after events will be reloaded.
                    that.props.alert(TranslationService.trans(that.props.language, 'common.actions.save_success'));
                    that.props.closeEvent();
                    that.props.eventAction();
                    that.setState({loaded: false});
                }).catch(function(error) {
                    that.props.preloaderHide();
                    that.props.alert(TranslationService.trans(that.props.language, 'common.actions.save_failed'));
                });

            return;
        }

        axios.post(config.apiAddress + '/app/calendar/event', data)
            .then(function (response) {
                //I'm not hide poreloader because it will be hidden by CalendarEventsContainer after events will be reloaded.
                that.props.alert(TranslationService.trans(that.props.language, 'common.actions.save_success'));
                that.props.closeEvent();
                that.props.eventAction();
                that.setState({loaded: false});
            }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert(TranslationService.trans(that.props.language, 'common.actions.save_failed'));
            });
    };

    /**
     * Load events for week
     */
    deleteEvent = () => {
        this.setState({delete_event: config.apiAddress + '/app/calendar/event/' + this.props.eventId});
    };

    /**
     * @param response
     * @param address
     */
    deleteEventSuccess = (response, address) => {
        this.props.alert(TranslationService.trans(this.props.language, 'common.actions.save_success'));
        this.props.closeEvent();
        this.props.eventAction();
        this.setState({loaded: false, delete_event: null});
    };

    /**
     * @param error
     * @param address
     */
    deleteEventError = (error, address) => {
        this.setState({loaded: false, delete_event: null});
        this.props.alert(TranslationService.trans(this.props.language, 'common.actions.save_failed'));
    };

    /**
     * @returns {*}
     */
    isOpen = () => {
        return (this.props.eventId === null ? null : ' opened');
    };

    /**
     * @returns {{float: string}}
     */
    getDeleteButtonStyle = () => {
        let style = {float: 'left'};

        if (this.props.eventId === '__NEW__') {
            style.display = 'none';
        }

        return style;
    }

    handleTypeChange = (event) => {
        if (event.target.checked) {
            this.setState({type: 'remainder'})
        } else {
            this.setState({type: 'event'})
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div>
                <dialog className={"imd-dialog imd-bg-transparent-black " + this.isOpen()}>
                    <div className="imd-dialog-body imd-bg-20-default imd-text-accent">
                        <header>
                            {TranslationService.trans(this.props.language, 'calendars.event.name')}
                            <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => {this.props.closeEvent(); this.setState({loaded: false});}} />
                        </header>

                        <div className="imd-dialog-content">
                            <div className="imd-row">
                                <label className="imd-col-sm-4 imd-col-xs-6">
                                    {TranslationService.trans(this.props.language, 'calendars.event.date')}
                                    <input type="date" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.date} onChange={e => {this.setState({date: e.target.value});}} />
                                </label>

                                <label className="imd-col-sm-4 imd-col-xs-6">
                                    {TranslationService.trans(this.props.language, 'calendars.event.time')}
                                    <input type="time" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.time} onChange={e => {this.setState({time: e.target.value});}} />
                                </label>

                                <label className="imd-col-sm-4 imd-col-xs-12">
                                    {TranslationService.trans(this.props.language, 'calendars.event.length')}
                                    <select className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" value={this.state.length} onChange={e => {this.setState({length: e.target.value});}}>
                                        <option value="15">15 {TranslationService.trans(this.props.language, 'common.minutes_short')}</option>
                                        <option value="30">30 {TranslationService.trans(this.props.language, 'common.minutes_short')}</option>
                                        <option value="45">45 {TranslationService.trans(this.props.language, 'common.minutes_short')}</option>
                                        <option value="60">1 {TranslationService.trans(this.props.language, 'common.hours_short')}</option>
                                        <option value="90">1.5 {TranslationService.trans(this.props.language, 'common.hours_short')}</option>
                                        <option value="120">2 {TranslationService.trans(this.props.language, 'common.hours_short')}</option>
                                        <option value="180">3 {TranslationService.trans(this.props.language, 'common.hours_short')}</option>
                                        <option value="240">4 {TranslationService.trans(this.props.language, 'common.hours_short')}</option>
                                        <option value="300">5 {TranslationService.trans(this.props.language, 'common.hours_short')}</option>
                                        <option value="360">6 {TranslationService.trans(this.props.language, 'common.hours_short')}</option>
                                        <option value="1440">{TranslationService.trans(this.props.language, 'calendars.event.all_day')}</option>
                                    </select>
                                </label>

                                <div className="imd-col-xs-12" style={{display: (this.props.user === null || this.props.user.subscription === 'standard') ? 'none' : 'block', marginBottom: '13px'}}>
                                    <input
                                        type="checkbox"
                                        id="calendar-remainder-checkbox"
                                        className="imd-form-checkbox"
                                        checked={this.state.type === 'remainder'}
                                        onChange={this.handleTypeChange}
                                    />
                                    <label htmlFor="calendar-remainder-checkbox" className="imd-text-main">
                                        <span className="imd-border-color-main imd-bg-160-accent" />
                                        {TranslationService.trans(this.props.language, 'calendars.event.sms')}
                                    </label>
                                </div>

                                <label className="imd-col-xs-12">
                                    {TranslationService.trans(this.props.language, 'calendars.event.description')}
                                    <textarea className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" value={this.state.description} onChange={e => {this.setState({description: e.target.value});}} />
                                </label>
                            </div>

                            <div className="imd-row">
                                <label className="imd-col-xs-12">
                                    {TranslationService.trans(this.props.language, 'calendars.event.repeat.name')}
                                    <select className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" value={this.state.repeat} onChange={e => {this.setState({repeat: e.target.value});}}>
                                        <option value="">&nbsp;</option>
                                        <option value="daily">{TranslationService.trans(this.props.language, 'calendars.event.repeat.daily')}</option>
                                        <option value="weekly">{TranslationService.trans(this.props.language, 'calendars.event.repeat.weekly')}</option>
                                        <option value="monthly">{TranslationService.trans(this.props.language, 'calendars.event.repeat.monthly')}</option>
                                        <option value="yearly">{TranslationService.trans(this.props.language, 'calendars.event.repeat.yearly')}</option>
                                    </select>
                                </label>
                            </div>

                            <div className="imd-row">
                                <label className="imd-col-md-6 imd-col-xs-12">
                                    {TranslationService.trans(this.props.language, 'calendars.event.participants')}
                                    <input type="text" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.participants} onChange={e => {this.setState({participants: e.target.value});}} />
                                </label>
                                <label className="imd-col-md-6 imd-col-xs-12">
                                    {TranslationService.trans(this.props.language, 'calendars.event.place')}
                                    <input type="text" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.place} onChange={e => {this.setState({place: e.target.value});}} />
                                </label>
                            </div>
                        </div>

                        <footer>
                            <button className="imd-button imd-button-outline imd-border-color-red imd-text-red" style={this.getDeleteButtonStyle()} onClick={this.deleteEvent}>
                                {TranslationService.trans(this.props.language, 'common.delete')}
                            </button>
                            <button className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => {this.props.closeEvent(); this.setState({loaded: false});}}>
                                {TranslationService.trans(this.props.language, 'common.cancel')}
                            </button>
                            <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={this.saveEvent}>
                                {TranslationService.trans(this.props.language, 'common.save')}
                            </button>
                        </footer>
                    </div>
                </dialog>

                <Confirmation
                    address={this.state.delete_event}
                    onCancel={() => this.setState({delete_event: null})}
                    onSuccess={this.deleteEventSuccess}
                    onError={this.deleteEventError}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        user: state.user,
        language: TranslationService.getLanguage(state.user)
    }
};

export const EventDialog = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(EventDialogClass);
